@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Uni Sans";
  src: local("Uni Sans"),
    url("./assets/fonts/Uni-Sans/uni-sans.heavy-caps.otf") format("truetype");
  font-weight: 900;
  font-style: bold;
}

@font-face {
  font-family: "Uni Sans";
  src: local("Uni Sans"),
    url("./assets/fonts/Uni-Sans/Uni-Sans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}



